'use strict';

import InputChangerController from './InputChangerController';

class RacePrognosisForm {
  constructor(container, embedPopup) {
    this.renderItemRecent = this.renderItemRecent.bind(this);
    this.renderItemGoal = this.renderItemGoal.bind(this);
    this.onChange = this.onChange.bind(this);
    this.insertRecentValues = this.insertRecentValues.bind(this);
    this.insertGoalValues = this.insertGoalValues.bind(this);
    this.changeUnits = this.changeUnits.bind(this);
    this.toggleWarning = this.toggleWarning.bind(this);
    this.submiter = this.submiter.bind(this);
    this.recentRaceTitle = container.find('#prognosis_form_recent_race_title');
    this.selectedTitle = this.recentRaceTitle.val();
    this.goalRaceTitle = container.find('#prognosis_form_goal_race_title');
    this.embedLink = container.find('.embed_code_link');
    this.embedCode = embedPopup.find('.embed_code_text');
    this.distance = this._findInput(container, 'prognosis_form_distance_km', 'prognosis_form_distance_mi');
    this.uphill = this._findInput(container, 'prognosis_form_uphill', 'prognosis_form_uphill_ft');
    this.downhill = this._findInput(container, 'prognosis_form_downhill', 'prognosis_form_downhill_ft');
    this.duration = container.find('#prognosis_form_duration');
    this.courseId = container.find('#prognosis_form_course_id');
    this.raceId = container.find('#prognosis_form_race_id');
    this.eventId = container.find('#prognosis_form_event_id');
    this.recentEventId = container.find('#prognosis_form_recent_event_id');
    const unitsSelect = container.find('[data-action=change-units]');
    unitsSelect.customSelect();
    this.units = unitsSelect.val();
    this.courseSelect = container.find('[data-action=change-course]');
    this.courseSelect.customSelect();
    this.prognosis_input = container.find('#prognosis_form_email');
    this.gdpr_warning = container.find('#gdpr_warning');
    this.submit_race_prognosis = container.find('#submit_race_prognosis');
    this.container = container;
  }

  init() {
    this.initTimepicker();
    this.initAutocomplete();
    this.prognosis_input.on('keyup', this.toggleWarning);
    this.submit_race_prognosis.on('click', this.submiter)
    return this.container.on('change', '[data-action=change-units]', this.changeUnits);
  }

  initTimepicker() {
    return this.duration.timepicker(window.timepicker_params());
  }

  initAutocomplete() {
    let recentDateFrom = new Date();
    recentDateFrom.setFullYear( recentDateFrom.getFullYear() - 3 );
    const recentDateFromString = recentDateFrom.toISOString().split('T')[0]

    let recentDateTo = new Date();
    const recentDateToString = recentDateTo.toISOString().split('T')[0]

    let goalDateFrom = new Date();
    const goalDateFromString = goalDateFrom.toISOString().split('T')[0]

    this.recentRaceTitle
      .autocomplete({
        source: `/social_events/search_new?` +
          `q[start_date_from]=${recentDateFromString}&` +
          `q[start_date_to]=${recentDateToString}&` +
          `q[course_distance_from]=3000&` +
          `q[race_sport_type_ids]=1&` +
          `q[verified_only]=false&` +
          `discard_start_date=true`,
        appendTo: '.search-results',
        minLength: 3,
        select: this.insertRecentValues,
        change: this.onChange}).autocomplete('instance')
      ._renderItem = this.renderItemRecent;

    this.goalRaceTitle
      .autocomplete({
        source: `/social_events/search_new?` +
          `q[start_date_from]=${goalDateFromString}&` +
          `q[course_distance_from]=3000&` +
          `q[race_sport_type_ids]=1&` +
          `q[verified_only]=false&` +
          `discard_start_date=false`,
        appendTo: '.search-results',
        minLength: 3,
        select: this.insertGoalValues}).autocomplete('instance')
      ._renderItem = this.renderItemGoal;
  }

  renderItemRecent(ul, item) {
    if (item.race_title != "") {
      return $('<li>').text(`${item.event_title} - ${item.race_title}: ${this._formattedDistance(item.distance)} ${this._formattedUpDownhill(item.uphill)}↑ ${this._formattedUpDownhill(item.downhill)}↓`).appendTo(ul);
    }
    else{
      return $('<li>').text(`${item.event_title}: ${this._formattedDistance(item.distance)} ${this._formattedUpDownhill(item.uphill)}↑ ${this._formattedUpDownhill(item.downhill)}↓`).appendTo(ul);
        }
    }

  renderItemGoal(ul, item) {
    if (item.race_title != "") {
      return $('<li>').text(`${this._formatDate(item.start_date)} - ${item.event_title} - ${item.race_title}: ${this._formattedDistance(item.distance)} ${this._formattedUpDownhill(item.uphill)}↑ ${this._formattedUpDownhill(item.downhill)}↓`).appendTo(ul);
    }
    else{
      return $('<li>').text(`${this._formatDate(item.start_date)} - ${item.event_title}: ${this._formattedDistance(item.distance)} ${this._formattedUpDownhill(item.uphill)}↑ ${this._formattedUpDownhill(item.downhill)}↓`).appendTo(ul);
    }
  }

  onChange(event, ui) {
    if (this.selectedTitle !== this.recentRaceTitle.val()) {
      // allow to edit unknown race attrs
      this.distance.removeAttr('readonly');
      this.uphill.removeAttr('readonly');
      return this.downhill.removeAttr('readonly');
    }
  }

  insertRecentValues(event, ui) {
    const { item } = ui;
    this.recentRaceTitle.val(`${item.event_title} ${this._formattedDistance(item.distance)}`);
    this.recentEventId.val(item.event_id);
    this.distance.val(this._convertDistance(item.distance));
    this.uphill.val(this._convertUpDownhill(item.uphill));
    this.downhill.val(this._convertUpDownhill(item.downhill));
    // make race attrs readonly
    this.selectedTitle = this.recentRaceTitle.val();
    this.distance.attr('readonly', 'readonly');
    this.uphill.attr('readonly', 'readonly');
    this.downhill.attr('readonly', 'readonly');
    return false;
  }

  _formattedDistance(value) {
    const shortUnits = this.units === 'statute' ? 'mi' : 'km';
    return `${this._convertDistance(value)} ${shortUnits}`;
  }

  _convertDistance(value) {
    const service = new DistanceConverter(value);
    if (this.units === 'metric') { return service.convert('m_to_km'); }
    return service.convert('m_to_mi');
  }

  _formattedUpDownhill(value) {
    const shortUnits = this.units === 'statute' ? 'ft' : 'm';
    return `${this._convertUpDownhill(value)} ${shortUnits}`;
  }
  _convertUpDownhill(value) {
    const service = new DistanceConverter(value);
    if (this.units === 'metric') { return value; }
    return service.convert("m_to_ft");
  }

  _formatDate(value) {
    const date = $.datepicker.parseDate('yy-mm-dd', value);
    return $.datepicker.formatDate(this._dateFormatCurrentLocale(), date);
  }

  _dateFormatCurrentLocale() {
    return I18n.t('date.formats.default')
      .replace('%d', 'dd')
      .replace('%m', 'mm')
      .replace('%Y', 'yy');
  }

  insertGoalValues(event, ui) {
    const { item } = ui;
    this.goalRaceTitle.val(`${item.event_title} ${this._formattedDistance(item.distance)}`);
    this.courseId.val(item.course_id);
    this.raceId.val(item.race_id);
    this.eventId.val(item.event_id);
    this.embedLink.removeClass('hidden');
    this.embedLink.find('a').attr('title', I18n.t('race_prognosis.tooltip', {race: item.event_title}));
    this.embedCode.text(`<iframe width=\"960px\" height=\"1000px\" src=\"https://runningcoach.me/${I18n.locale}/race_prognosis?iframe=true&course_id=${item.course_id}&race_id=${item.race_id}&event_id=${item.event_id}\" frameborder=\"0\" allowfullscreen></iframe>`);
    return false;
  }

  changeUnits(event) {
    let labels;
    const value = this.container.find('[data-action=change-units]').val();
    this.units = value;
    this._changeCourseSelectOptions();
    if (value === 'metric') {
      this._changeInputsToMetric();
      labels = [
        I18n.t('simple_form.labels.race.distance'),
        I18n.t('simple_form.labels.race.uphill'),
        I18n.t('simple_form.labels.race.downhill')
      ];
      return this._changeLabels(labels);
    } else {
      this._changeInputsToMiles();
      labels = [
        I18n.t('simple_form.labels.race.distance_mi'),
        I18n.t('simple_form.labels.race.uphill_ft'),
        I18n.t('simple_form.labels.race.downhill_ft')
      ];
      return this._changeLabels(labels);
    }
  }

  _changeInputsToMetric() {
     const values = [
      'prognosis_form_distance_mi,prognosis_form_distance_km,prognosis_form[distance_km],,mi_to_km',
      'prognosis_form_uphill_ft,prognosis_form_uphill,prognosis_form[uphill],,ft_to_m',
      'prognosis_form_downhill_ft,prognosis_form_downhill,prognosis_form[downhill],,ft_to_m'
     ];
     return this._changeForm(values);
   }

  _changeInputsToMiles() {
     const values = [
      'prognosis_form_distance_km,prognosis_form_distance_mi,prognosis_form[distance_mi],,km_to_mi',
      'prognosis_form_uphill,prognosis_form_uphill_ft,prognosis_form[uphill_ft],,m_to_ft',
      'prognosis_form_downhill,prognosis_form_downhill_ft,prognosis_form[downhill_ft],,m_to_ft'
     ];
     return this._changeForm(values);
   }

  _changeForm(values) {
    return _.each(values, el => this._changeInput.apply(this, el.split(',')));
  }

  _changeInput(currSelector, newSelector, name, placeholder, direction) {
    const div = this.container.find(`.${currSelector}`);
    div.removeClass(currSelector).addClass(newSelector);
    const input = div.find('input');
    return this._setInputValues(input, newSelector, name, placeholder, direction);
  }

  _setInputValues(input, newSelector, name, placeholder, direction) {
    const options = {
      name,
      placeholder,
      id: newSelector,
      direction
    };
    const service = new InputChangerController(input);
    return service.change(options);
  }

  _findInput(container, name1, name2) {
    const input = container.find(`#${name1}`);
    if (input.length) { return input; }
    return container.find(`#${name2}`);
  }

  _findLabel(container, name1, name2) {
    const input = container.find(`.${name1} label`);
    if (input.length) { return input; }
    return container.find(`.${name2} label`);
  }

  _changeLabels(labels) {
    this._findLabel(this.container, 'prognosis_form_distance_km', 'prognosis_form_distance_mi').text(labels[0]);
    this._findLabel(this.container, 'prognosis_form_uphill', 'prognosis_form_uphill_ft').text(labels[1]);
    return this._findLabel(this.container, 'prognosis_form_downhill', 'prognosis_form_downhill_ft').text(labels[2]);
  }

  _changeCourseSelectOptions() {
    _.each(this.courseSelect.find('option'), option => {
      const val = $(option).text();
      const converter = new DistanceConverter(parseFloat(val));
      if (this.units === 'statute') {
        return $(option).text(converter.convert('km_to_mi'));
      } else {
        return $(option).text(converter.convert('mi_to_km'));
      }
    });

    const node = this.courseSelect.parent().find('.customSelectInner');
    const val = node.text();
    const converter = new DistanceConverter(parseFloat(val));
    if (this.units === 'statute') {
      node.text(converter.convert('km_to_mi'));
    } else {
      node.text(converter.convert('mi_to_km'));
    }

    const label = this.courseSelect.parent().find('label');
    if (this.units === 'statute') {
      return label.text(I18n.t('race_prognosis.course_mi'));
    } else {
      return label.text(I18n.t('race_prognosis.course_km'));
    }
  }

  toggleWarning() {
    if (this.prognosis_input.val() === '') {
      return this.gdpr_warning.css('display', 'none');
    } else {
      this.gdpr_warning.css('display', 'block');
    }
  };

  submiter() {
    this.container.submit();
  }
}

export default RacePrognosisForm;
