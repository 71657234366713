"use strict";

import React from "react";
import ReactDOM from "react-dom";
import Filters from "../../src/components/Filters";

document.addEventListener("DOMContentLoaded", () => {
  const container = document.getElementById("filter_container");
  const initialFilters = JSON.parse(container.getAttribute("data-filters"));
  const initialScope = JSON.parse(container.getAttribute("data-scope"));
  const unit = container.getAttribute("data-units");
  const translations = JSON.parse(container.getAttribute("data-translations"));
  const locale = container.getAttribute("data-locale");
  const user_country = container.getAttribute("data-country");

  // Header shrink

  const headerContainer = document.getElementById("shrinkable-section");
  const initialHeight = headerContainer.offsetHeight;
  const maxHeight = initialHeight;
  const minHeight = 0;
  const scrollThreshold = 200;

  window.addEventListener("scroll", () => {
    const scrolled = window.scrollY;
    const newHeight = Math.max(minHeight, maxHeight - (scrolled / scrollThreshold) * (maxHeight - minHeight));
    headerContainer.style.height = `${newHeight}px`;
    if (headerContainer.offsetHeight !== initialHeight) {
      headerContainer.style.opacity = newHeight / initialHeight - 0.05;
    } else {
      headerContainer.style.opacity = 1;
    }
  });

  // Cards animation

  const observerOptions = {
    root: null,
    rootMargin: "0px",
    threshold: 0.5,
  };

  function intersectionCallback(entries, observer) {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        entry.target.classList.add("animated", "fadeInUp");
        observer.unobserve(entry.target);
      }
    });
  }

  const observer = new IntersectionObserver(intersectionCallback, observerOptions);

  function observeElements() {
    const elements = document.querySelectorAll(".animate-in"); // Change the selector as needed
    elements.forEach((el) => observer.observe(el));
  }

  document.body.addEventListener("htmx:afterSettle", (event) => {
    observeElements();
  });

  observeElements();

  function removeDeprecatedFilters() {
    const obj = initialFilters.table;
    for (const key in obj) {
      if (obj.hasOwnProperty(key) && key.includes("downhill")) {
        delete obj[key];
      }
    }
    return obj;
  }

  const filters = removeDeprecatedFilters();
  const scope = initialScope.table;
  const transl = translations.table;

  ReactDOM.render(
    <Filters
      filters={filters}
      scope={scope}
      unit={unit}
      translations={transl}
      locale={locale}
      user_country={user_country}
    />,
    container
  );
});
