import { DEFAULT_FILTERS } from "../constants/constants";
import { PARAMS } from "../constants/typings";
import DistanceConverter from "../DistanceConverter";

export default class Helpers {
  static metersToKilometers(value) {
    return DistanceConverter.metersToKilometers(value) + " km";
  }

  static metersToMiles(value) {
    return DistanceConverter.metersToMiles(value) + " mi";
  }

  static metersToFeets(value) {
    return DistanceConverter.metersToFeets(value) + " ft";
  }

  static formatMeters(value) {
    return value + " m";
  }

  static formatDate(dateString: string) {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-indexed
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  }

  static buildUrl(data: PARAMS) {
    const filteredObj = Object.fromEntries(
      Object.entries(data).filter(([key, value]) => {
        if (key === "start_date_from") return true;
        if (key === "submit") return false;
        if (DEFAULT_FILTERS[key] !== undefined && value === DEFAULT_FILTERS[key]) {
          return false;
        }
        return true;
      })
    );
    const queryString = Object.entries(filteredObj)
      .filter(([key, value]) => value !== null)
      .map(([key, value]) => `&q[${encodeURIComponent(key)}]=${encodeURIComponent(value)}`)
      .join("");
    return queryString;
  }
}
