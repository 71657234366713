'use strict';

class RaceAutocompleteController {
  constructor(container, units) {
    this.setUnit = this.setUnit.bind(this);
    this.renderItem = this.renderItem.bind(this);
    this.onChange = this.onChange.bind(this);
    this.insertValues = this.insertValues.bind(this);
    this.title = container.find('#race_title');
    this.selected_title = this.title.val();
    this.date = container.find('#race_start_date');
    this.race_db_event_id = container.find('#race_race_db_event_id');
    this.race_db_course_id = container.find('#race_race_db_course_id');
    this.distance = this._findInput(container, 'race_distance_km', 'race_distance_mi');
    this.uphill = this._findInput(container, 'race_uphill', 'race_uphill_ft');
    this.downhill = this._findInput(container, 'race_downhill', 'race_downhill_ft');
    this.units = units;
  }

  initAutocomplete() {
    let dateFrom = new Date();
    const dateFromString = dateFrom.toISOString().split('T')[0]

    this.title.autocomplete({
      source: `/social_events/search_new?` +
        `q[start_date_from]=${dateFromString}&` +
        `q[course_distance_from]=3000&` +
        `q[race_sport_type_ids]=1&` +
        `q[verified_only]=false&` +
        `discard_start_date=false`,
      appendTo: '.search-results',
      minLength: 3,
      select: this.insertValues,
      change: this.onChange
    });
    const instance = this.title.autocomplete('instance');
    if (instance) {
      return instance._renderItem = this.renderItem;
    }
  }

  setUnit(value) {
    return this.units = value;
  }

  renderItem(ul, item) {
    if (item.race_title != "") {
      return $('<li>').text(`${this._formatDate(item.start_date)} - ${item.event_title} - ${item.race_title}: ${this._formattedDistance(item.distance)} ${this._formattedUpDownhill(item.uphill)}↑ ${this._formattedUpDownhill(item.downhill)}↓`).appendTo(ul);
    }
    else{
      return $('<li>').text(`${this._formatDate(item.start_date)} - ${item.event_title}: ${this._formattedDistance(item.distance)} ${this._formattedUpDownhill(item.uphill)}↑ ${this._formattedUpDownhill(item.downhill)}↓`).appendTo(ul);
    }
  }

  onChange(event, ui) {
    if (this.selected_title !== this.title.val()) {
      // allow to edit unknown race attrs
      this.race_db_event_id.val("");
      this.race_db_course_id.val("");
      this.distance.removeAttr("readonly");
      this.uphill.removeAttr("readonly");
      this.downhill.removeAttr("readonly");
      const start_year = new Date(1072915200000).getFullYear();
      const current_year = new Date().getFullYear();
      return this.date.removeAttr("readonly").datepicker(
        window.datepicker_params({
          yearRange: start_year + ':' + (current_year + 5)
        })
      );
    }
  }

  insertValues(event, ui) {
    this.race_db_event_id.val(ui.item.event_id);
    this.race_db_course_id.val(ui.item.course_id);
    this.title.val(`${ui.item.event_title} ${this._formattedDistance(ui.item.distance)}`);
    this.date.val(this._formatDate(ui.item.start_date));
    this.distance.val(this._convertDistance(ui.item.distance));
    this.uphill.val(this._convertUpDownhill(ui.item.uphill));
    this.downhill.val(this._convertUpDownhill(ui.item.downhill));
    // make race attrs readonly
    this.selected_title = this.title.val();
    this.distance.attr("readonly", "readonly");
    this.uphill.attr("readonly", "readonly");
    this.downhill.attr("readonly", "readonly");
    return false;
  }

  _findInput(container, name1, name2) {
    const input = container.find(`#${name1}`);
    if (input.length) { return input; }
    return container.find(`#${name2}`);
  }

  _formattedDistance(distance) {
    const shortUnits = this.units === 'statute' ? 'mi' : 'km';
    return `${this._convertDistance(distance)} ${shortUnits}`;
  }

  _convertDistance(value) {
    const service = new DistanceConverter(value);
    if (this.units === 'metric') { return service.convert('m_to_km'); }
    return service.convert('m_to_mi');
  }

  _formattedUpDownhill(value) {
    const shortUnits = this.units === 'statute' ? 'ft' : 'm';
    return `${this._convertUpDownhill(value)} ${shortUnits}`;
  }

  _convertUpDownhill(value) {
    const service = new DistanceConverter(value);
    if (this.units === 'metric') { return value; }
    return service.convert("m_to_ft");
  }

  _formatDate(value) {
    const date = $.datepicker.parseDate('yy-mm-dd', value);
    return $.datepicker.formatDate(this._dateFormatCurrentLocale(), date);
  }

  _dateFormatCurrentLocale() {
    return I18n.t('date.formats.default')
      .replace('%d', 'dd')
      .replace('%m', 'mm')
      .replace('%Y', 'yy');
  }
}

export default RaceAutocompleteController;
